import { ComponentConfig } from 'components/src/utils/ComponentOverridesContext'

import AccountHero from './components/AccountHero/AccountHero'
import AccountIcon from './components/AccountIcon'
import AddressBookIcon from './components/AddressBookIcon'
import BackIcon from './components/BackIcon'
import BackToTopIcon from './components/BackToTopIcon'
import BookmarkIcon, { BookmarkFilledIcon } from './components/BookmarkIcon'
import { CarIcon } from './components/CarIcon/CarIcon'
import CartEmptyIcon from './components/CartEmptyIcon'
import CartIcon from './components/CartIcon'
import CheckIcon from './components/CheckIcon'
import CloseIcon from './components/CloseIcon'
import DashboardIcon from './components/DashboardIcon'
import DropdownIcon from './components/DropdownIcon'
import HeroVideoCard from './components/HeroVideoCard'
import Logo from './components/Logo'
import NewsletterSubmit from './components/NewsletterSubmit'
import NotFoundBackgroundImages from './components/NotFoundPage/NotFoundBackgroundImages'
import OrdersIcon from './components/OrdersIcon'
import PickupPointIcon from './components/PickupPointIcon'
import PlusIcon from './components/PlusIcon'
import PostalAddressIcon from './components/PostalAddressIcon'
import SearchIcon from './components/SearchIcon'
import StoresIcon from './components/StoresIcon'
import SuccessPageBackgroundImages from './components/SuccessPage/SuccessPageBackgroundImages'
import TileSlider from './components/TileSlider'
import UserDetailsIcon from './components/UserDetailsIcon'
import UspCheckIcon from './components/UspCheckIcon'
import ThemeOverrides from './theme/ThemeOverrides'

const componentsConfig: ComponentConfig = {
    AccountHero,
    AccountIcon,
    AddressBookIcon,
    BackIcon,
    BackToTopIcon,
    BookmarkIcon,
    BookmarkFilledIcon,
    CartEmptyIcon,
    CartIcon,
    CheckIcon,
    CloseIcon,
    DashboardIcon,
    DropdownIcon,
    Logo,
    NotFoundBackgroundImages,
    OrdersIcon,
    PickupPointIcon,
    PlusIcon,
    PostalAddressIcon,
    SearchIcon,
    StoresIcon,
    SuccessPageBackgroundImages,
    UserDetailsIcon,
    UspCheckIcon,
    HeroVideoCard,
    TileSlider,
    NewsletterSubmit,
    ThemeOverrides,
    ProgressIndicator: CarIcon,
}

export default componentsConfig
