import {
    Props,
    TileSlider as DefaultTileSlider,
} from 'components/src/TileSlider'
import HeadingColor from 'components/src/types/HeadingColor'

import styles from './TileSlider.module.scss'

const parseText = (header: React.ReactNode) => {
    if (typeof header === 'string' && header.includes('|')) {
        return header.split('|').map((part, index) => (
            <div key={index} className={styles.part}>
                {part}
            </div>
        ))
    }

    return header
}

const TileSlider = (props: Props) => (
    <DefaultTileSlider
        {...props}
        header={parseText(props.header)}
        headingColor={
            props.style === 'light' ? HeadingColor.extra : HeadingColor.light
        }
        buttonStyle={props.style === 'light' ? 'primary' : 'white'}
        tileButtonStyle={props.style === 'light' ? 'primary' : 'white'}
    />
)

export default TileSlider
