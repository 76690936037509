import { t, defineMessage } from '@lingui/macro'
import makeUrl from 'components/src/makeUrl'

import { getActiveStoreView, setStoreViews, StoreView } from '@emico/storeviews'

const storeViews: Array<
    StoreView & {
        prismicLocale?: string
    }
> = [
    {
        code: 'castiron_en',
        language: 'en',
        locale: 'en-gb',
        name: defineMessage({
            id: 'core.storeConfig.castiron_en',
            message: 'International',
        }),
        location: undefined,
        makeUrl: makeUrl(),
        basename: '',
        default: true,
        storeId: 16,
        websiteId: 7,
        prismic: 'castiron',
        prismicLocale: 'en-gb',
    },
    {
        code: 'castiron_nl',
        language: 'nl',
        locale: 'nl-nl',
        name: defineMessage({
            id: 'core.storeConfig.castiron_nl',
            message: 'Netherlands',
        }),
        location: (location) =>
            location.pathname === '/nl' || location.pathname.startsWith('/nl/'),
        makeUrl: makeUrl('nl'),
        basename: '/nl',
        default: false,
        storeId: 17,
        websiteId: 7,
        prismic: 'castiron',
        prismicLocale: 'nl-nl',
    },
]

const activeStoreView = getActiveStoreView(storeViews, window.location)
setStoreViews(storeViews, activeStoreView)

export default storeViews
