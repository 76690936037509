import HeroImage from 'components/src/cms-pages/HomePage/HeroImage'
import absolutify from 'components/src/utils/url/absolutify'
import * as React from 'react'

import { useBreakpoints } from '@emico/ui'

import DesktopBackgroundImage from './images/SuccessBackgroundDesktop.jpg'
import MobileBackgroundImage from './images/SuccessBackgroundMobile.jpg'

const SuccessPageBackgroundImages = ({ className }: { className?: string }) => {
    const { isMobile } = useBreakpoints()

    return (
        <HeroImage
            url={absolutify(
                !isMobile ? DesktopBackgroundImage : MobileBackgroundImage,
            )}
            className={className}
        />
    )
}

export default SuccessPageBackgroundImages
