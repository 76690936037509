import cx from 'classnames'
import { LazyProductPage } from 'components/src/chunks'
import { UrlRewriteEntityTypeEnum } from 'components/src/graphql/schema.generated'
import ImageLazy from 'components/src/media/ImageLazy'
import { PlaybackEvent } from 'components/src/media/Video/Video'
import Link from 'components/src/navigation/Link'
import PriceBox, { getPrices } from 'components/src/presentation/PriceBox'
import { ProductCardFragment } from 'components/src/ProductCardFragment'
import Heading from 'components/src/typography/Heading'
import useHover from 'components/src/utils/useHover'
import { useEffect, useMemo, useState } from 'react'

import styles from './Card.module.scss'

// Determines how long the card is visible after it is made "active".
// A card becomes "active" when its quepoint in the video is reached.
// It becomes "inactive" when the next quepoint is reached, or when
// the video ends
const CARD_VISIBILITY_DURATION = 6100

interface Props extends Omit<React.HTMLAttributes<HTMLElement>, 'onClick'> {
    product: ProductCardFragment
    selectedCard: string | undefined
    activeCard: string | undefined
    carouselEnabled: boolean
    isVideoReady: boolean
    progress: PlaybackEvent
    onClick?(
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
        product: ProductCardFragment,
    ): void
    onHover?(product: ProductCardFragment, isHovered: boolean): void
}

const HeroVideoCard = ({
    product,
    activeCard,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    selectedCard,
    onClick,
    onHover,
    carouselEnabled,
    isVideoReady,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    progress,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    style,
    ...otherProps
}: Props) => {
    const [hoverRef, isHovered] = useHover<HTMLElement>()
    const [isVisible, setVisible] = useState(false)
    const [timer, setTimer] = useState<NodeJS.Timeout>()
    const isActive = activeCard === product.sku

    /* eslint-disable react-hooks/exhaustive-deps */
    // We disable exhaustive-deps here because the code below
    // should not update when timer is changed.
    // A new timer should only be set when isActive becomes true (again)
    useEffect(() => {
        const stopTimer = () => {
            if (!timer) {
                return
            }
            clearTimeout(timer)
            setTimer(undefined)
        }
        if (isActive) {
            setVisible(true)
            setTimer(
                setTimeout(() => {
                    setVisible(false)
                }, CARD_VISIBILITY_DURATION),
            )
        } else {
            stopTimer()
        }
        return stopTimer
    }, [isActive])
    /* eslint-enable react-hooks/exhaustive-deps */

    const images = {
        image: product.smallImage?.url || '',
        smallWidth: 170,
        mediumWidth: 170,
        regularWidth: 170,
    }

    const handleClick = (
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    ) => {
        if (onClick) {
            onClick(event, product)
        }
    }

    const handleMouseEnter = () => {
        LazyProductPage.preload()
    }

    useEffect(() => {
        if (onHover) {
            onHover(product, isHovered)
        }
    }, [isHovered, product, onHover])

    const prices = useMemo(() => getPrices(product), [product])

    return (
        <Link
            name={product.name}
            category="productCard"
            to={`/${product.urlKey}`}
            title={product.name}
            onClick={handleClick}
            onMouseEnter={handleMouseEnter}
            className={cx(styles.link, {
                [styles.animate]: isVideoReady,
            })}
            preload="hover"
            resolver={{
                type: UrlRewriteEntityTypeEnum.PRODUCT,
                id: product.id,
                redirectCode: 0,
            }}
        >
            <article
                {...otherProps}
                ref={hoverRef}
                className={cx(styles.base, {
                    [styles.productList]: !carouselEnabled,
                    [styles.active]: isActive && isVisible,
                    [styles.hovered]: isHovered,
                })}
            >
                <div className={styles.card}>
                    <div className={styles.block} />
                    <div className={styles.imageAnimationWrapper}>
                        <div className={styles.imageWrapper}>
                            <ImageLazy
                                alt={product.smallImage?.label || product.name}
                                images={images}
                                className={styles.image}
                                lazy={false}
                            />
                        </div>
                    </div>
                </div>

                <Heading
                    element="h1"
                    variant="h4"
                    color="light"
                    className={styles.title}
                >
                    {product.name}
                </Heading>

                <div className={styles.priceWrapper}>
                    <div className={styles.progress} />
                    <PriceBox {...prices} priceClassName={styles.price} />
                </div>
            </article>
        </Link>
    )
}

export default HeroVideoCard
