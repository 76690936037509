import { css, Global } from '@emotion/react'
import { varKeys as colorVarKeys } from 'components/src/theme/colors'
import { varKeys as typographyVarKeys } from 'components/src/theme/typography'
import { varKeys as utilVarKeys } from 'components/src/theme/utils'

const ThemeOverrides = () => (
    <Global
        styles={css`
            :root {
                ${utilVarKeys.topbarMenuOffset}: 150px;

                ${colorVarKeys.grayDark}: #010202;
                ${colorVarKeys.primary}: #00358e;
                ${colorVarKeys.primaryExtra}: #2553a0;

                ${colorVarKeys.secondary}: #85929e;
                ${colorVarKeys.secondaryExtra}: #85929e;

                ${colorVarKeys.badgeBg}: #fff;
                ${colorVarKeys.badgeFill}: #00358e;
                ${colorVarKeys.badgeText}: #00358e;

                ${colorVarKeys.textGrey}: #505050;

                ${colorVarKeys.onBackground}: #00358e;
                ${colorVarKeys.darkBackground}: #00358e;

                ${typographyVarKeys.fonts.primary}: 'Work Sans', Helvetica,
                    Arial, sans-serif;
                ${typographyVarKeys.fonts.secondary}: 'Minion Pro', Helvetica,
                    Arial, sans-serif;
                ${typographyVarKeys.fonts
                    .footer}: 'Work Sans', Helvetica, Arial,
                    sans-serif;
                ${typographyVarKeys.headingSizes.footer}: clamp(
                    18px,
                    2.6vw,
                    22px
                );

                ${colorVarKeys.footerTopBackground}: #2553a0;
                ${colorVarKeys.footerDivider}: var(${colorVarKeys.footerTopBackground});
                ${colorVarKeys.footerAccordionBorder}: var(${colorVarKeys.footerTopBackground});

                ${colorVarKeys.footerMainBackground}: var(
                    ${colorVarKeys.darkBackground}
                );
                ${colorVarKeys.footerTopBottomBorder1}: none;

                ${colorVarKeys.promoBannerBackground}: var(
                    ${colorVarKeys.sale}
                );

                --popper-select-font: var(${colorVarKeys.primary});
                --popper-select-color: var(${colorVarKeys.black});
            }
        `}
    />
)

export default ThemeOverrides
