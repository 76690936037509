import { Logo as DefaultLogo, Props } from 'components/src/core/Logo/Logo'
import * as React from 'react'

import LogoFooter from './logo-footer.svg?react'
import LogoStandard from './logo.svg?react'

const Logo = (props: Props) => (
    <DefaultLogo {...props} logo={LogoStandard} footerLogo={LogoFooter} />
)

export default Logo
