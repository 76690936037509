import Button from 'components/src/input/Button'
import { NewsletterSubmit as OriginalNewsletterSubmit } from 'components/src/newsletter/SubscribeToNewsletterForm'
import * as React from 'react'

const NewsletterSubmit = (
    props: React.ComponentProps<typeof OriginalNewsletterSubmit>,
) => (
    <Button
        {...props}
        type="submit"
        // eslint-disable-next-line react/prop-types
        variant={props.theme === 'opaque' ? 'primary' : 'default'}
    />
)

export default NewsletterSubmit
