/* eslint-disable @typescript-eslint/no-var-requires */
// List the font files here that should be preloaded for speed.
// Fonts should be woff2 format since that's most supported.
import proMedium from './MinionPro-Medium.woff2'
import regular from './MinionPro-Regular.woff2'
import proSemibold from './MinionPro-Semibold.woff2'
import medium from './WorkSans-Medium.woff2'
import semiBold from './WorkSans-SemiBold.woff2'

export default [medium, semiBold, regular, proMedium, proSemibold]
